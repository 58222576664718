import React from 'react';
export let Therapeutic_Areas = [
  {
    name: 'Healthy Volunteers',
  },
  {
    name: 'BA/BE Studies',
  },
  {
    name: 'Vaccines',
  },
  {
    name: 'Obesity',
  },
  {
    name: 'Marijuana Users',
  },
  {
    name: 'Others Upon Request',
  },
];

const OutpatientClinicalTrial = () => {
  return (
    <>
      <div className='container pt-5'>
        <div className='row'>
          {Therapeutic_Areas.map((item, index) => (
            <div className='col-md-3 pt-3'>
              <button className='tAreas_Button'>{item.name}</button>
            </div>
          ))}
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default OutpatientClinicalTrial;

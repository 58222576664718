import React from 'react';

const SectionFour = () => {
  return (
    <>
      <div className='container-fluid section_Four_bg'>
        <div className='row'>
          <div className='content' style={{ background: '#307FAFC7', color: 'white' }}>
            We welcome collaborations, inquiries, and partnerships from researchers, clinicians, industry professionals,
            and other stakeholders who share our vision of advancing healthcare through research. Join us in our mission
            to transform healthcare outcomes and shape the future of medicine.
            <br />
            <br /> Together, we can make a difference.
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionFour;

import axios from 'axios';
import React, { useState } from 'react';

const SectionOne = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    phone: '',
    email: '',
    clinical: '',
  });

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var data = JSON.stringify(formData);

    var config = {
      method: 'post',
      url: 'http://localhost:5000/api/product/getbill',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <>
      <br />
      <div className='container-fluid'>
        <div className='row'>
          <div id='carouselExampleIndicators' className='carousel slide' data-bs-ride='carousel'>
            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <div className='row'>
                  <div className='col-md-5'>
                    <div className='animiOne'></div>
                    <h2 className='slider_font_size'>Advancing Healthcare through Innovative Clinical Reserch</h2>
                  </div>
                  <div className='col-md-7 padding_slider_for_mobile'>
                    <img className='slider_img_fixed d-block w-100' src='./images/imgOne.jpg' alt='First slide' />
                  </div>
                  <div className='animiTwo' style={{ zIndex: '-1' }}></div>
                </div>
              </div>
              <div className='carousel-item'>
                <div className='row'>
                  <div className='col-md-5'>
                    <div className='animiOne'></div>
                    <h2 className='slider_font_size'>
                      Excellence in Research
                      <br />
                      <br />
                      <br />
                    </h2>
                  </div>
                  <div className='col-md-7 padding_slider_for_mobile'>
                    <img className='slider_img_fixed d-block w-100' src='./images/imgTwo.jpg' alt='Second slide' />
                  </div>
                  <div className='animiTwo' style={{ zIndex: '-1' }}></div>
                </div>
              </div>
              <div className='carousel-item'>
                <div className='row'>
                  <div className='col-md-5'>
                    <div className='animiOne'></div>
                    <h2 className='slider_font_size'>
                      Patient-Centered Approach
                      <br />
                      <br />
                    </h2>
                  </div>
                  <div className='col-md-7 padding_slider_for_mobile'>
                    <img className='slider_img_fixed d-block w-100' src='./images/imgThree.jpg' alt='Third slide' />
                  </div>
                  <div className='animiTwo' style={{ zIndex: '-1' }}></div>
                </div>
              </div>
              <div className='carousel-item'>
                <div className='row'>
                  <div className='col-md-5'>
                    <div className='animiOne'></div>
                    <h2 className='slider_font_size'>
                      Multidisciplinary Collaboration
                      <br />
                      <br />
                    </h2>
                  </div>
                  <div className='col-md-7 padding_slider_for_mobile'>
                    <img className='slider_img_fixed d-block w-100' src='./images/imgFour.jpg' alt='Third slide' />
                  </div>
                  <div className='animiTwo' style={{ zIndex: '-1' }}></div>
                </div>
              </div>
              <div className='carousel-item'>
                <div className='row'>
                  <div className='col-md-5'>
                    <div className='animiOne'></div>
                    <h2 className='slider_font_size'>
                      Ethical and Regulatory Compliance
                      <br />
                      <br />
                    </h2>
                  </div>
                  <div className='col-md-7 padding_slider_for_mobile'>
                    <img className='slider_img_fixed d-block w-100' src='./images/imgFive.jpg' alt='Third slide' />
                  </div>
                  <div className='animiTwo' style={{ zIndex: '-1' }}></div>
                </div>
              </div>
              <div className='carousel-item'>
                <div className='row'>
                  <div className='col-md-5'>
                    <div className='animiOne'></div>
                    <h2 className='slider_font_size'>
                      Global Collaboration
                      <br />
                      <br />
                      <br />
                    </h2>
                  </div>
                  <div className='col-md-7 padding_slider_for_mobile'>
                    <img className='slider_img_fixed d-block w-100' src='./images/imgSix.jpg' alt='Third slide' />
                  </div>
                  <div className='animiTwo' style={{ zIndex: '-1' }}></div>
                </div>
              </div>
              <div className='carousel-item'>
                <div className='row'>
                  <div className='col-md-5'>
                    <div className='animiOne'></div>
                    <h2 className='slider_font_size'>
                      Data Integrity and Quality
                      <br />
                      <br />
                    </h2>
                  </div>
                  <div className='col-md-7 padding_slider_for_mobile'>
                    <img className='slider_img_fixed d-block w-100' src='./images/imgSeven.jpg' alt='Third slide' />
                  </div>
                  <div className='animiTwo' style={{ zIndex: '-1' }}></div>
                </div>
              </div>
            </div>
            <a className='carousel-control-prev' href='#carouselExampleIndicators' role='button' data-bs-slide='prev'>
              <span className='carousel-control-prev-icon' aria-hidden='true'></span>
              <span className='visually-hidden'>Previous</span>
            </a>
            <a className='carousel-control-next' href='#carouselExampleIndicators' role='button' data-bs-slide='next'>
              <span className='carousel-control-next-icon' aria-hidden='true'></span>
              <span className='visually-hidden'>Next</span>
            </a>
          </div>
        </div>
        <br />
        <br />
        <button className='btn_find_trail' onClick={openModal}>
          FIND YOUR TRIAL
        </button>
        {/* <div className='animiTwo'></div> */}
      </div>
      {modalOpen && (
        <div
          className='modal slide-in-top'
          style={{
            display: 'block',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        >
          <div
            className='modal-content model_content_width'
            style={{ backgroundColor: '#fff', padding: '20px', margin: 'auto', marginTop: '10%' }}
          >
            <form class='container' onSubmit={handleSubmit}>
              <div class='form-group'>
                <label for='firstName' className='pb-2 pt-2'>
                  <b>Name</b> <span style={{ color: 'darkred' }}>*</span>
                </label>
                <input
                  type='text'
                  value={formData.firstName}
                  onChange={handleChange}
                  class='form-control'
                  id='firstName'
                  name='firstName'
                />
              </div>
              <div class='form-group'>
                <label for='phone' className='pb-2 pt-2'>
                  <b>Phone Number</b> <span style={{ color: 'darkred' }}>*</span>
                </label>
                <input
                  type='text'
                  value={formData.phone}
                  onChange={handleChange}
                  class='form-control'
                  id='phone'
                  name='phone'
                />
              </div>
              <div class='form-group'>
                <label for='email' className='pb-2 pt-2'>
                  <b>Email</b> <span style={{ color: 'darkred' }}>*</span>
                </label>
                <input
                  type='text'
                  value={formData.email}
                  onChange={handleChange}
                  class='form-control'
                  id='email'
                  name='email'
                />
              </div>

              <div class='form-group'>
                <label for='clinical-trial' className='pb-2 pt-2'>
                  <b>Clinical Trial</b> <span style={{ color: 'darkred' }}>*</span>
                </label>
                <select
                  class='form-control'
                  value={formData.clinical}
                  id='clinical'
                  onChange={handleChange}
                  name='clinical'
                >
                  <option value='option1'>--Select--</option>
                  <option value='Obesity'>Obesity</option>
                  <option value='Diabetes'>Diabetes</option>
                  <option value='Oncology'>Oncology</option>
                  <option value='Central Nervous system'>Central Nervous system</option>
                  <option value='Infectious Disease & Vaccines'>Infectious Disease & Vaccines</option>
                  <option value='Cardiovascular'>Cardiovascular</option>
                  <option value='Dermatology'>Dermatology</option>
                  <option value='GI & Hepatology'>GI & Hepatology</option>
                  <option value='Endocrinology'>Endocrinology</option>
                  <option value='Allergy & respiratory'>Allergy & respiratory</option>
                  <option value='Rheumatology'>Rheumatology</option>
                  <option value='Ophthalmology'>Ophthalmology</option>
                  <option value='Nephrology'>Nephrology</option>
                  <option value='Reproductive health'>Reproductive health</option>
                  <option value='Womens health'>Women's health</option>
                  <option value='Pain'>Pain</option>
                  <option value='Obesity'>Obesity</option>
                  <option value='Tobacco evalution'>Tobacco evalution</option>
                </select>
              </div>
              <br />
              <input
                className='submit_btn'
                type='submit'
                value='Submit'
                style={{ borderRadius: '5px', backgroundColor: '#066AAB', fontSize: '12px', fontFamily: 'sans-serif' }}
              />
              <hr />
              <div class='form-group'>
                <button
                  class='btn btn-secondary'
                  style={{ cursor: 'pointer', float: 'right', border: 'none', borderRadius: '5px' }}
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default SectionOne;

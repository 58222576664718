import React from 'react';
import { Link } from 'react-router-dom';

const Patients = () => {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <img src='./images/Join-Us-Today_Patients.jpg' alt='' style={{ height: 'auto', width: '100%' }} />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <h2 style={{ fontSize: '34px', fontWeight: '600', color: 'black', marginBottom: '40px' }}>Patients</h2>
          </div>
        </div>
        <div className='row p-2'>
          <div className='col-md-1'></div>
          <div className='col-md-1 p-0 mt-2'>
            <div className='numberOne'>1</div>
          </div>
          <div className='col-md-9 p-3' style={{ boxShadow: '19px 6px 46px 4px rgba(0, 0, 0, 0.75)' }}>
            <h5 style={{ fontFamily: '"Amiko", Sans-serif', fontSize: '34px', fontWeight: '500', color: '#0c7bc0' }}>
              APPLY
            </h5>
            In order to sign up to participate in a clinical research study, you must have a participant application
            form completed and on file with us. If you're interested in participating in future studies, take a few
            moments to complete contact us form (Please use below button)
            <br />
            <br />
            <Link to='/contact-us'>
              <button className='btn btn-secondary'>Contact Us</button>
            </Link>
            <br />
            <br />
            If you've previously completed an application and would like to update your information or check your
            application status, please call us at <b>+1 609 450 3535</b>
            <br />
            <br /> Hours of Operation Monday thru Friday: <b>8:00 am - 5:00 pm</b>
          </div>
          <div className='col-md-1'></div>
        </div>
        <br />
        <br />
        <br />
        <div className='row p-2'>
          <div className='col-md-1'></div>
          <div className='col-md-1 p-0 mt-2'>
            <div className='numberOne'>2</div>
          </div>
          <div className='col-md-9 p-3 mb-5' style={{ boxShadow: '19px 6px 46px 4px rgba(0, 0, 0, 0.75)' }}>
            <h5 style={{ fontFamily: '"Amiko", Sans-serif', fontSize: '34px', fontWeight: '500', color: '#0c7bc0' }}>
              GET COMPENSATED
            </h5>
            You will be compensated for your time and transportation while participating in a clinical research study at
            Trialfinity. Stipends are typically paid daily throughout the study. Taxes are not taken out. If you earn
            $600 or more in a year, we will send you an IRS form 1099 at the end of the year. Since these are not
            “earned wages”, and you are not employed by us, they are only subject to state and federal taxes.
          </div>
          <div className='col-md-1'></div>
        </div>
      </div>
    </>
  );
};

export default Patients;
